import type { NextPage } from 'next'
import { Container } from 'atoms'
import { Layout, Main } from 'layout'
import Head from 'next/head'
import Link from 'next/link'

const Home: NextPage = () => (
  <Layout>
    <Head>
      <title>Seite nicht gefunden | FrauenCoach</title>
      <meta name="description" content="FrauenCoach Seite nicht gefunden" />
      <meta content="noindex,follow" name="robots"></meta>
    </Head>
    <Main>
      <Container className="text-center">
        <h1>Seite nicht gefunden</h1>
        <p>
            Leider konnten wir die gesuchte Adresse nicht anzeigen.
        </p>
        <p className="text-smaller">
          {/* eslint-disable-next-line max-len */}
          Eventuell wurde die Adresse falsch geschrieben oder wir haben den Inhalt verschoben. Wir bitten um Verzeihung.
        </p>
        <Link href="/">
          Zur Startseite
        </Link>
      </Container>
    </Main>
  </Layout>
)

export default Home
